import React from 'react'
import { Helmet } from 'react-helmet'

import LayoutStorelocator from '../../layout/storelocator'

import Locator from '../../components/locator'

import Retailer from '../../svg/retailer.svg'

import Puzzle from '../../svg/storelocator/puzzle.svg'
import Chart from '../../svg/storelocator/chart.svg'
import Clock from '../../svg/storelocator/clock.svg'
import Sync from '../../svg/storelocator/sync.svg'
import Map from '../../svg/storelocator/map.svg'

const Page = () => (
        <LayoutStorelocator language="nl">

            <Helmet>
                <title>Zupr - Storelocator</title>
                <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
            </Helmet>

            <section className="text-left image-right divider">
                <div className="inner">

                    <div className="text">
                        <h2>Ontdek de store locator van Zupr</h2>
                        <p>Zupr voegt een geheel nieuwe dimensie toe aan store locator software. Met klassieke store locators beheert u zelf alle gegevens van verkooppunten. Met Zupr werkt u samen met winkeliers om de lokale verkrijgbaarheid van uw producten in kaart te brengen. Past een verkooppunt bijvoorbeeld zijn openingstijden of assortiment aan, dan zijn deze wijzigingen direct zichtbaar op uw website. Dit bespaart u tijd en de consument heeft altijd correcte informatie.</p>
                        <a className="btn btn-demo" href="mailto:sales@zupr.io?subject=Store locator demo">Probeer nu gratis</a>
                    </div>

                    <div className="image">
                        <Retailer />
                    </div>

                </div>
            </section>

            <section className="text-center image-bottom divider">
                <div className="inner">

                    <div className="text">
                        <h2>Er is meer: van store locator naar product locator</h2>
                        <p>Met de meeste store locators kunt u alleen laten zien waar uw merk wordt verkocht, terwijl de consument direct wil kunnen zien waar een bepaald product te koop is. Met Zupr kunt u daarom tot op productniveau inzichtelijk maken in welke winkels uw producten op voorraad zijn. Zo weet de consument direct waar hij aan toe is!</p>
                        <Locator />
                    </div>

                </div>
            </section>

            <section className="text-center">
                <div className="inner">

                    <div className="text">
                        <h2>Werk samen met uw lokale verkooppunten</h2>
                        <p>Wanneer u de gegevens en afbeeldingen van uw producten digitaal bij Zupr aanlevert, hoeven winkeliers dit zelf niet meer te doen. Zo houdt u de controle over uw productgegevens en merkidentiteit en kunnen winkeliers veel makkelijker doorgeven wat hun assortiment is. Met onze ZuprCharger app is dit zelfs voor de kleinste winkelier mogelijk.</p>
                    </div>

                </div>
            </section>

            <section className="text-center grey">
                <div className="inner">

                    <div className="text">
                        <h2>Voordelen van Zupr</h2>
                        <div className="usps">
                            <div className="block with-icon">
                                <h3><Puzzle />Eenvoudige integratie</h3>
                                <p>U kunt de store locator van Zupr eenvoudig integreren in uw website. Het is mogelijk om de store locator aan te passen aan de huisstijl van uw merk.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Chart />Statistieken</h3>
                                <p>In een overzichtelijk dashboard vindt u aantrekkelijk gepresenteerde real-time data. Heatmaps bieden bijvoorbeeld direct inzicht in de lokale vraag naar uw producten.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Clock />Direct starten</h3>
                                <p>Zodra uw account is aangemaakt, kunt u meteen aan de slag. In uw beheeromgeving kunt u producten toevoegen en direct retailers uitnodigen om door te geven wat ze daarvan in hun assortiment hebben.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Sync />Up-to-date</h3>
                                <p>Doordat retailers zelf hun gegevens beheren, beschikt de consument altijd over correcte openingstijden en contactgegevens. Ook voorraadinformatie is zichtbaar, een belangrijk aspect voor de consument.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Map />Route plannen</h3>
                                <p>Consumenten kunnen direct een route plannen naar de dichtstbijzijnde winkel die uw producten verkoopt waarbij ze ook kunnen sorteren op prijs en voorraad.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="text-center divider">
                <div className="inner">

                    <div className="text">
                        <h2>Prijzen</h2>
                        <p>Neem voor een prijsopgave contact met ons op via <a href="mailto:sales@zupr.io">sales@zupr.io</a>.</p>
                        <div style={{display: 'none'}}>
                            <p>Alle tarieven beschikken over dezelfde functionaliteiten/features.<br/>Prijzen zijn gebaseerd op het aantal winkels dat aangesloten is.</p>
                            <ul className="prices">
                                <li>
                                    <h4>1 - 5 winkels</h4>
                                    <p>€ 7,50 per maand</p>
                                </li>
                                <li>
                                    <h4>6 - 100 winkels</h4>
                                    <p>€ 17,50 per maand</p>
                                </li>
                                <li>
                                    <h4>101 - 500 winkels</h4>
                                    <p>€ 35,- per maand</p>
                                </li>
                                <li>
                                    <h4>500+ winkels</h4>
                                    <p>€ 50,- per maand</p>
                                </li>
                            </ul>
                            <p><small>Heeft u vragen? Neem dan <a href="mailto:support@zupr.nl">contact</a> op met het Zupr team.</small></p>
                        <p>Of u nu groot of klein bent, we vinden wel dat we u altijd dezelfde inzichten moeten kunnen verstrekken. Uiteraard kunt u maandelijks uw abonnement aanpassen op basis van een groei of daling in het aantal winkels. Annuleren mag natuurlijk altijd. Per maand opzeggen is bij Zupr geen probleem!</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="contact-boost">
                <div className="inner">
                    <h2>Heeft u vragen?</h2>
                    <p>Voor vragen of toelichting kunt u contact opnemen met het Zupr team.</p>
                    <p>
                        <a href="mailto:support@zupr.nl?subject=Vraag over Zupr" className="email">support@zupr.nl</a>
                        <a href="tel:+31854011306" className="telephone">+31 (0)85 40 11 306</a>
                    </p>
                </div>
            </section>

    </LayoutStorelocator>
)

export default Page
